<template>
  <div class="card my-3" :style="{ backgroundImage: `url(${background})` }">
    <div class="card-body">
      <h4 class="text-center">Butuh Bantuan?</h4>
      <p>
        Sampaikan kesulitan Anda melalui
        <a href="mailto:teknis@rumahweb.com">teknis@rumahweb.com</a>, melalui
        <a href="https://ticket.srs-x.net/" target="_blank">trouble ticket</a>,
        atau livechat
      </p>
      <div class="text-center">
        <button
          @click="toggleLivechat"
          class="btn btn-warning"
          style="border-radius: 72px"
        >
          Chat Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { LIVECHAT } from "@/store/rw/util.module";
import { mapGetters } from "vuex";

export default {
  name: "WidgetBantuan",
  data() {
    return {
      background: "/assets/rw/svg/bg-butuh-bantuan.svg"
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * for trigger livechat open/close
     * --------------------------------------------------
     */
    toggleLivechat() {
      this.$store.dispatch(LIVECHAT, {
        btnBanner: false,
        chatOpen: !this.configLivechat.chatOpen
      });
    }
  },
  computed: {
    ...mapGetters(["configLivechat"])
  }
};
</script>

<style scoped>
.card {
  background-size: cover !important;
  color: #181c32 !important;
}
</style>
