<template>
  <div>
    <RWCard title="Domain Management">
      <template v-slot:body>
        <!-- alert -->
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong><i class="fa fa-bell pr-1"></i></strong> Domain locking
          disabled! Detail »
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <b-row>
          <!-- domain lock -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              data-toggle="modal"
              data-target="#modalTransferLock"
            >
              <img :src="icon.domainLock" svg-inline class="icon" />
              <h6 class="mt-3">Domain Lock</h6>
            </b-card>
          </b-col>
          <!-- end domain lock -->

          <!--  Nameserver Setting -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              data-toggle="modal"
              data-target="#modalNameServer"
            >
              <img :src="icon.nameServer" svg-inline class="icon" />
              <h6 class="mt-3">Nameserver Setting</h6>
            </b-card>
          </b-col>
          <!-- end Nameserver Setting -->

          <!--  Private Nameserver Setting -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              @click="routeToManage('privatens')"
            >
              <img :src="icon.privateNS" svg-inline class="icon" />
              <h6 class="mt-3">Private Nameserver</h6>
            </b-card>
          </b-col>
          <!-- end Private Nameserver Setting -->

          <!--  DNS Management -->
          <b-col sm="3" cols="6">
            <b-card class="text-center my-2" @click="routeToManage('dns')">
              <img :src="icon.dnsManagement" svg-inline class="icon" />
              <h6 class="mt-3">DNS Management</h6>
            </b-card>
          </b-col>
          <!-- end DNS Management -->

          <!--  DNSSEC -->
          <b-col sm="3" cols="6">
            <b-card class="text-center my-2" @click="routeToManage('dnssec')">
              <img
                :src="icon.dnsSec"
                svg-inline
                class="icon"
                style="width: 37px"
              />
              <h6 class="mt-4">DNSSEC</h6>
            </b-card>
          </b-col>
          <!-- end DNSSEC -->

          <!--  EPP Code -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              data-toggle="modal"
              data-target="#modalEppCode"
            >
              <img
                :src="icon.eppCode"
                svg-inline
                class="icon"
                style="width: 37px"
              />
              <h6 class="mt-4">EPP Code</h6>
            </b-card>
          </b-col>
          <!-- end EPP Code -->

          <!-- domain Domain Forwarding -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              @click="routeToManage('forwarding')"
            >
              <img
                :src="icon.domainForwarding"
                svg-inline
                class="icon"
                style="width: 50px"
              />
              <h6 class="mt-4">Domain Forwarding</h6>
            </b-card>
          </b-col>
          <!-- end Domain Forwarding -->

          <!--  Contact Management -->
          <b-col sm="3" cols="6">
            <b-card class="text-center my-2" @click="routeToManage('contact')">
              <img
                :src="icon.contact"
                svg-inline
                class="icon"
                style="width: 35px"
              />
              <h6 class="mt-3">Contact Management</h6>
            </b-card>
          </b-col>
          <!-- end Contact Management -->

          <!--  Privacy Management -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              data-toggle="modal"
              data-target="#modalIdProtection"
            >
              <img
                :src="icon.idProtection"
                svg-inline
                class="icon"
                style="width: 35px"
              />
              <h6 class="mt-3">Privacy Management</h6>
            </b-card>
          </b-col>
          <!-- end Privacy Management -->

          <!-- Sosial Media Redirect -->
          <b-col sm="3" cols="6">
            <b-card
              class="text-center my-2"
              @click="routeToManage('sosmedredirect')"
            >
              <img :src="icon.domainForwarding" svg-inline class="icon" />
              <h6 class="mt-3">Sosial Media Redirect</h6>
            </b-card>
          </b-col>
          <!-- end Sosial Media Redirect -->
        </b-row>
      </template>
    </RWCard>

    <!-- slot modal management -->
    <RWModalManagement :DomainID="DomainID"></RWModalManagement>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import RWModalManagement from "@/views/pages/product/slot/slotModalManagement.vue";
export default {
  name: "DomainManagement",
  props: {
    DomainID: {
      required: true,
      type: String
    },
    DomainName: {
      required: true,
      type: String
    }
  },
  components: {
    RWCard,
    RWModalManagement
  },
  data() {
    return {
      /**
       * -----------------------------------------
       * init icon domain management
       * -----------------------------------------
       */
      icon: {
        dnsSec: "/assets/rw/svg/domain-management/dnssec.svg",
        eppCode: "/assets/rw/svg/domain-management/epp-code.svg",
        contact: "/assets/rw/svg/domain-management/contact-management.svg",
        privateNS: "/assets/rw/svg/domain-management/private-nameserver.svg",
        domainLock: "/assets/rw/svg/domain-management/domain-lock.svg",
        nameServer: "/assets/rw/svg/domain-management/nameserver-setting.svg",
        idProtection: "/assets/rw/svg/domain-management/privacy-management.svg",
        dnsManagement: "/assets/rw/svg/domain-management/dns-management.svg",
        sosmedRedirect:
          "/assets/rw/svg/domain-management/social-media-redirection.svg",
        domainForwarding:
          "/assets/rw/svg/domain-management/domain-forwarding.svg"
      }
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * redirect to manage domain
     * -----------------------------------------
     */
    routeToManage(menu = false) {
      this.$router.push({
        name: "productDomainManage",
        params: { menu: menu, id: this.DomainID }
      });
    }
  },

  mounted() {}
};
</script>

<style scoped>
img.icon {
  width: 45px;
}
.card:hover {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.row .col-sm-3 .card {
  height: 92%;
}
</style>
