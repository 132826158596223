<template>
  <div>
    <!-- modal domain lock -->
    <div id="modalTransferLock" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Domain Lock</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="text-center">
              <div v-if="loading.domainLock">
                <div class="row justify-content-center">
                  <div class="col-sm-6">
                    <v-skeleton-loader type="text@3"></v-skeleton-loader>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-2">
                    <v-skeleton-loader type="button"></v-skeleton-loader>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="httpcode.domainLock === '200'">
                  <h1>
                    Domain Currently
                    <span
                      :class="
                        xhr.domainLock === '0' ? 'text-danger' : 'text-success'
                      "
                    >
                      {{ xhr.domainLock === "0" ? "Disabled" : "Enabled" }}
                    </span>
                  </h1>

                  <p class="text-left">
                    Lock your domain to prevent it from being transferred away
                    without your authorization.
                  </p>

                  <button
                    class="btn"
                    :class="
                      xhr.domainLock === '0'
                        ? 'btn-outline-success'
                        : 'btn-outline-danger'
                    "
                    @click="post_domainlock()"
                  >
                    {{ xhr.domainLock === "0" ? "Enable" : "Disabled" }}
                    Registrar Lock
                  </button>
                </div>
                <div v-else>
                  <b-alert show variant="danger">{{ xhr.domainLock }}</b-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal domain lock -->

    <!-- modal name server -->
    <div id="modalNameServer" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Nameservers</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body">
            <div v-if="loading.nameServer">
              <v-skeleton-loader type="paragraph@4"></v-skeleton-loader>
              <div class="justify-content-center">
                <v-skeleton-loader type="button"></v-skeleton-loader>
              </div>
            </div>

            <!-- else : loading=false -->
            <div v-else>
              <!-- if code == 200 -->
              <div v-if="httpcode.nameServer === '200'">
                <b-alert show>
                  You can change where your domain points to here. Please be
                  aware changes can take up to 24 hours to propagate.
                </b-alert>
                <form @submit="post_nameserver">
                  <div class="form-group row mb-0">
                    <label class="col-sm-3 col-form-label">Nameserver 1</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="xhr.nameServer.ns1"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label class="col-sm-3 col-form-label">Nameserver 2</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="xhr.nameServer.ns2"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label class="col-sm-3 col-form-label">Nameserver 3</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="xhr.nameServer.ns3"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label class="col-sm-3 col-form-label">Nameserver 4</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="xhr.nameServer.ns4"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label class="col-sm-3 col-form-label">Nameserver 5</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="xhr.nameServer.ns5"
                      />
                    </div>
                  </div>
                  <div class="text-right my-3">
                    <button class="btn btn-primary">Change Nameserver</button>
                  </div>
                </form>
              </div>

              <!-- else code !=200 -->
              <div v-else>
                <b-alert show variant="danger">{{ xhr.nameServer }}</b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal name server -->

    <!-- modal id protection -->
    <div id="modalIdProtection" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Privacy Management</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body">
            <div v-if="loading.idProtection">
              <div class="row justify-content-center">
                <div class="col-sm-6">
                  <v-skeleton-loader type="text@2"></v-skeleton-loader>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-sm-2">
                  <v-skeleton-loader type="button"></v-skeleton-loader>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- if code = 200 -->
              <div v-if="httpcode.idProtection === '200'">
                <h3 class="text-center">
                  Status :
                  <span
                    :class="
                      xhr.idProtection.status_ori.status
                        ? 'text-success'
                        : 'text-danger'
                    "
                    >{{ xhr.idProtection.status }}</span
                  >
                </h3>

                <div class="text-center">
                  <button
                    :class="
                      xhr.idProtection.status_ori.status
                        ? 'btn btn-danger'
                        : 'btn btn-success'
                    "
                    @click="post_idprotection()"
                  >
                    <i
                      :class="
                        xhr.idProtection.status_ori.status
                          ? 'fa fa-power-off'
                          : 'fa fa-shopping-cart'
                      "
                    ></i>
                    {{ xhr.idProtection.action }}
                  </button>
                </div>
              </div>
              <div v-else>
                <b-alert show variant="danger">{{ xhr.idProtection }}</b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- edn modal id protection -->

    <!-- modal epp code -->
    <div id="modalEppCode" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">EPP Code</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body">
            <div v-if="loading.eppCode">
              <div class="row justify-content-center">
                <div class="col-sm-6">
                  <v-skeleton-loader type="text@2"></v-skeleton-loader>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-sm-2">
                  <v-skeleton-loader type="button"></v-skeleton-loader>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- if code == 200 -->
              <div v-if="httpcode.eppCode == '200'">
                <div v-if="eppCodeChange.showForm">
                  <form @submit.prevent="post_eppcode">
                    <div class="form-group mb-0">
                      <label>New EPP Code : </label>
                      <b-form-input
                        type="text"
                        v-model.trim="$v.eppCodeChange.newEppCode.$model"
                        :state="validateState('newEppCode')"
                      ></b-form-input>

                      <div
                        class="invalid-feedback"
                        v-if="!$v.eppCodeChange.newEppCode.required"
                      >
                        EPP Code is required.
                      </div>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.eppCodeChange.newEppCode.minLength"
                      >
                        EPP Code must have at least
                        {{ $v.eppCodeChange.newEppCode.$params.minLength.min }}
                        letters.
                      </div>
                      <!-- <input type="text" v-model="$v.eppCodeChange.newEppCode.$model" class="form-control" > -->
                    </div>
                    <div class="">
                      <button
                        type="button"
                        class="btn btn-warning my-3 mr-2"
                        @click="eppCodeChange.showForm = false"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary my-3">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div v-else>
                  <h2 class="text-center">Eppcode :</h2>
                  <h4 class="text-center">{{ xhr.eppCode }}</h4>
                  <div class="text-center">
                    <button
                      class="btn btn-primary"
                      @click="copy_eppcode(xhr.eppCode)"
                    >
                      <i class="fa fa-clone"></i> Copy
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-warning"
                      @click="
                        eppCodeChange.showForm = true;
                        eppCodeChange.newEppCode = '';
                      "
                    >
                      <i class="far fa-edit"></i> Change
                    </button>
                  </div>
                </div>
              </div>

              <!-- else code != 200 -->
              <div v-else>
                <b-alert show variant="danger">{{ xhr.eppCode }}</b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal epp code -->
  </div>
</template>

<script>
/**
 * -----------------------------------------
 * import dependency package
 * -----------------------------------------
 */
import copy from "clipboard-copy";
import ApiService from "@/common/api.service";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import $ from "jquery";
export default {
  name: "slotDomainManagement",
  mixins: [validationMixin, loading, ApiURL, notif],
  props: {
    DomainID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      /**
       * -----------------------------------------
       * init loading
       * -----------------------------------------
       */
      loading: {
        domainLock: true,
        nameServer: true,
        idProtection: true,
        eppCode: true,
        sosmedRedirect: true
      },

      /**
       * -----------------------------------------
       * init & use data from api
       * -----------------------------------------
       */
      xhr: {
        eppCode: null,
        domainLock: null,
        nameServer: null,
        idProtection: null,
        sosmedRedirect: null
      },

      /**
       * ----------------------------------------
       * http code
       * default 200
       * jika respon 400 maka menampilkan error dari API
       * ----------------------------------------
       */
      httpcode: {
        eppCode: 200,
        nameServer: 200,
        domainLock: 200,
        idProtection: 200,
        sosmedRedirect: 200
      },

      /**
       * -----------------------------------------
       * variable reguler what need this component
       * -----------------------------------------
       */
      eppCodeChange: {
        showForm: false,
        newEppCode: ""
      }
    };
  },

  /**
   * -----------------------------------------
   * validasi form ex. nameserver / eppcode
   * -----------------------------------------
   */
  validations: {
    eppCodeChange: {
      newEppCode: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    /**
     * -----------------------------------------
     * validasi state form eppcode, cause use third party
     * -----------------------------------------
     */
    validateState(name) {
      const { $dirty, $error } = this.$v.eppCodeChange[name];
      return $dirty ? !$error : null;
    },

    /**
     * -----------------------------------------
     * get domain lock transfer
     * -----------------------------------------
     */
    get_domainlock() {
      this.xhr_domain("transferlock", this.DomainID)
        .then(({ data }) => {
          this.xhr.domainLock = data.data.status;
        })
        .catch(({ response }) => {
          this.httpcode.domainLock = response.status;
          this.xhr.domainLock = response.data.message;
        })
        .finally(() => {
          this.loading.domainLock = false;
        });
    },

    /**
     * -----------------------------------------
     * post ubah domain lock transfer
     * -----------------------------------------
     */
    post_domainlock() {
      $("#modalTransferLock").modal("hide");
      this.mx_loading();

      let param = {
        reseller_lock: this.xhr.domainLock === "0" ? "1" : "0"
      };
      this.xhr_domain_post("transferlock", param, this.DomainID)
        .then(() => {
          this.loading.domainLock = false;
          this.mx_swal("Domain lock has been change", "", "success");
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    /**
     * -----------------------------------------
     * get domain nameserver
     * -----------------------------------------
     */
    get_nameserver() {
      ApiService.setHeader();
      ApiService.get(this.path.nameserver, this.DomainID)
        .then(({ data }) => {
          this.xhr.nameServer = data.data.nameserver;
        })
        .catch(({ response }) => {
          this.httpcode.nameServer = response.status;
          this.xhr.nameServer = response.data.message;
        })
        .finally(() => {
          this.loading.nameServer = false;
        });
    },

    /**
     * -----------------------------------------
     * post ubah nameserver domain
     * -----------------------------------------
     */
    post_nameserver(e) {
      e.preventDefault();
      $("#modalNameServer").modal("hide");
      this.mx_loading();

      ApiService.setHeader();
      ApiService.post(
        this.path.nameserver + "/" + this.DomainID,
        this.xhr.nameServer
      )
        .then(() => {
          this.mx_swal("", "Success", "success");
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    /**
     * -----------------------------------------
     * get status idprotection / privacy management
     * -----------------------------------------
     */
    get_idprotection() {
      ApiService.setHeader();
      ApiService.get(this.path.idprotection, this.DomainID)
        .then(({ data }) => {
          let result = data.data.idprotection;
          this.xhr.idProtection = {
            status_ori: result,
            status: result.status ? "Aktif" : "Non aktif",
            action: result.status
              ? "Non Aktifkan"
              : `Buy Now for Rp ${result.price}/Year`
          };
        })
        .catch(({ response }) => {
          this.httpcode.idProtection = response.status;
          this.xhr.idProtection = response.data.message;
        })
        .finally(() => {
          this.loading.idProtection = false;
        });
    },

    /**
     * -------------------------------------------
     * post / ubah id protection
     * -------------------------------------------
     */
    post_idprotection() {
      $("#modalIdProtection").modal("hide");
      this.mx_loading();
      let post = {
        idprotection: this.xhr.idProtection.status_ori.status ? "0" : "1"
      };
      this.http
        .post(this.end.domain.idprotection, post, this.DomainID)
        .then(({ data }) => {
          this.mx_swal(data.message, "", "success");
          this.$router.push({
            path: "/invoice/" + data.data.idprotection.invoiceid
          });
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    /**
     * -----------------------------------------
     * get eppcode
     * -----------------------------------------
     */
    get_eppcode() {
      ApiService.setHeader();
      ApiService.get(this.path.epp, this.DomainID)
        .then(({ data }) => {
          this.xhr.eppCode = data.data.epp;
        })
        .catch(({ response }) => {
          this.xhr.eppCode = response.data.message;
          this.httpcode.eppCode = response.status;
        })
        .finally(() => {
          this.loading.eppCode = false;
        });
    },

    /**
     * -----------------------------------------
     * change eppcode
     * -----------------------------------------
     */
    post_eppcode() {
      // check error form
      this.$v.eppCodeChange.$touch();
      if (this.$v.eppCodeChange.$anyError) {
        return;
      }

      $("#modalEppCode").modal("hide");
      this.mx_loading();
      // call request
      ApiService.setHeader();
      ApiService.post(this.path.epp + "/" + this.DomainID, {
        eppcode: this.eppCodeChange.newEppCode
      })
        .then(({ data }) => {
          if (data.code === 200) {
            this.mx_swal(data.message, "Success", "success");
          }
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        })
        .finally(() => {
          this.mx_loading(false);
          this.eppCodeChange.showForm = false;
        });
    },

    /**
     * --------------------------------------------------------------
     * function copy eppcode
     * --------------------------------------------------------------
     */
    copy_eppcode: function(epp) {
      copy(epp);
      this.mx_toast("Eppcode has been copied", "success");
    }
  },

  computed: {
    /**
     * ------------------------------------
     * get url api from mixin
     * ------------------------------------
     */
    path() {
      return this.api.product.domain;
    }
  },
  mounted() {
    let self = this;

    /**
     * -----------------------------------------
     * trigger request ketika mounted / open modal
     * -----------------------------------------
     */
    let set_loading = index => {
      this.httpcode[index] = 200;
      this.loading[index] = true;
    };

    /**
     * ----------------------------------------
     * trigger request when open modal domain lock
     * ----------------------------------------
     */
    $("#modalTransferLock").on("show.bs.modal", () => {
      self.get_domainlock();
      set_loading("domainLock");
    });

    /**
     * ---------------------------------
     * trigger request when open modal name server
     * ---------------------------------
     */
    $("#modalNameServer").on("show.bs.modal", () => {
      self.get_nameserver();
      set_loading("nameServer");
    });

    /**
     * ---------------------------------
     * trigger request when open modal idprotection
     * ---------------------------------
     */
    $("#modalIdProtection").on("show.bs.modal", () => {
      self.get_idprotection();
      set_loading("idProtection");
    });

    /**
     * ---------------------------------
     * trigger request when open modal eppcode
     * ---------------------------------
     */
    $("#modalEppCode").on("show.bs.modal", () => {
      self.get_eppcode();
      set_loading("eppCode");
    });
  }
};
</script>
