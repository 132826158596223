<template>
  <div>
    <div v-if="loading">
      <div class="row">
        <div class="col-sm-3">
          <div class="card shadow">
            <div class="card-body">
              <v-skeleton-loader type="chip"></v-skeleton-loader> <br />
              <v-skeleton-loader type="text@3"></v-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="card mt-4 shadow">
            <div class="card-body">
              <v-skeleton-loader type="heading"></v-skeleton-loader> <br />
              <v-skeleton-loader type="sentences@3"></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component v-else :is="view" :status="xhr.status"></component>
  </div>
</template>

<script>
import statusActive from "@/views/pages/product/domain/product-domain-active.vue";
import statusNonActive from "@/views/pages/product/slot/slotLayananNonActive.vue";
import domainNotInRumahweb from "@/views/pages/product/slot/slotDomainNotInRW.vue";
import { productDomainMixin } from "@/common/mixins/product/product-mixin.js";
import { breadcrumb, httpCode, loading } from "@/common/mixins/general.js";

export default {
  name: "productDomainSingleActive",
  mixins: [breadcrumb, httpCode, loading, productDomainMixin],
  components: { statusActive, statusNonActive, domainNotInRumahweb },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      /**
       * -----------------------------------------
       * tampung data API
       * -----------------------------------------
       */
      xhr: {
        status: {
          status: null
        }
      },

      /**
       * --------------------------------------------------------------
       * jika referal dari page lagin (bukan list domain)
       * false jika status 400
       * --------------------------------------------------------------
       */
      domainInternal: true
    };
  },
  methods: {
    /**
     * -----------------------------------------
     * get status domain
     * -----------------------------------------
     */
    get_status(domain_id) {
      this.mx_loading();
      this.mx_status_domain(domain_id)
        .then(({ data }) => {
          this.xhr.status = data.data.status;
          this.mx_DomainBreadcrumb([{ title: data.data.status.domain }]);
        })
        .catch(({ response }) => {
          let query = this.$route.query;
          if (
            typeof query.ref !== "undefined" &&
            typeof query.domain !== "undefined"
          ) {
            let refs = ["hosting"];
            if (refs.includes(query.ref)) {
              this.domainInternal = false;
            }
          }
          this.xhr.status = response.data;
        })
        .finally(() => {
          this.mx_loading(false);
          this.loading = false;
        });
    }
  },
  computed: {
    view() {
      if (!this.domainInternal) {
        return "domainNotInRumahweb";
      } else {
        return this.xhr.status.status === "Active" && this.domainInternal
          ? "statusActive"
          : "statusNonActive";
      }
    }
  },
  watch: {
    /**
     * ----------------------------------------
     * jika inject domain id url
     * ----------------------------------------
     */
    $route(to) {
      this.get_status(to.params.id);
    }
  },
  mounted() {
    this.get_status(this.id);
  }
};
</script>
