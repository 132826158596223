import { render, staticRenderFns } from "./product-domain-active.vue?vue&type=template&id=65eb66b6&scoped=true&"
import script from "./product-domain-active.vue?vue&type=script&lang=js&"
export * from "./product-domain-active.vue?vue&type=script&lang=js&"
import style0 from "./product-domain-active.vue?vue&type=style&index=0&id=65eb66b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65eb66b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
