<template>
  <b-row>
    <!-- left  -->
    <b-col sm="3">
      <div class="card shadow-sm">
        <div class="card-body" style="padding: 0">
          <ul class="list-group list-group-flush">
            <li class="list-group-item active">Overview</li>
            <li
              class="list-group-item"
              @click="mx_redirect('https://order2.rumahweb.com/', false)"
            >
              Beli Hosting
            </li>
            <!-- <li class="list-group-item">Renew</li>
            <li class="list-group-item">Cancel</li> -->
          </ul>
        </div>
      </div>

      <RWCard title="Status" class="mt-3">
        <template v-slot:body>
          <div v-if="loading.status">
            <v-skeleton-loader type="text@6"></v-skeleton-loader>
          </div>
          <div v-else>
            <p>
              Status : <span class="text-success">{{ status.status }}</span>
              <br />
              Expire Date : {{ status.expired }} <br />
              Due Date : {{ status.duedate }} <br />
              Billing Cycle : {{ status.billingcycle }} <br />
              Price : {{ status.price }}
            </p>
          </div>
        </template>
      </RWCard>

      <!-- guides -->
      <RWWidgetGuides
        :data="xhr.guide"
        :loading="loading.guide"
        :success="success.guide"
      ></RWWidgetGuides>

      <!-- help -->
      <RWWidgetBantuan></RWWidgetBantuan>
    </b-col>

    <!-- right -->
    <b-col sm="9">
      <!-- domain management -->
      <RWDomainManagement
        :DomainID="status.id"
        :DomainName="status.domain"
      ></RWDomainManagement>

      <div class="row">
        <div class="col-sm-6 my-3">
          <!-- slider -->
          <div v-if="loading.upsell">
            <b-skeleton-img></b-skeleton-img>
          </div>
          <b-carousel
            v-else
            id="carousel-1"
            v-model="slide"
            :interval="10000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="700"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide img-blank img-alt="Blank image">
              <h3><b>Beli Hosting</b></h3>
              <h6>Harga mulai Rp. 15.000/bulan</h6>
              <p>
                Dengan layanan hosting, Anda dapat membuat website, email
                nama@namadomain
              </p>
              <a
                :href="xhr.upsell.hosting.link"
                class="btn btn-outline-light"
                target="_blank"
                >Beli</a
              >
            </b-carousel-slide>

            <b-carousel-slide img-blank img-alt="Blank image">
              <h3><b>Buat Website</b></h3>
              <h6>Hanya Rp. 99.000,-</h6>
              <p>
                Kirimkan materinya dan website Anda siap mendunia dalam 24 jam
                saja. Anda juga bisa menggunakan email nama@namadomain agar
                lebih terpercaya.
              </p>
              <a
                :href="xhr.upsell.website.link"
                class="btn btn-outline-light"
                target="_blank"
                >Click Here</a
              >
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div class="col-sm-6">
          <!-- sosial connect -->
          <RWCard class="mt-3" title="Social Connect">
            <template v-slot:body>
              <p>
                Biasa jualan di Instagram? Anda bisa menjadikan IG Anda sebagai
                website Anda. Bisa juga digunakan untuk Facebook, Twitter, atau
                lapak marketplace.
              </p>

              <div class="row">
                <div class="col-sm-6">
                  <!-- <a href="" class="btn btn-warning btn-block">Pelajari</a> -->
                </div>
                <div class="col-sm-6">
                  <b-link
                    class="btn btn-success btn-block"
                    :to="{
                      name: 'productDomainManage',
                      params: { id: status.id, menu: 'sosmedredirect' }
                    }"
                    >Setup</b-link
                  >
                </div>
              </div>
            </template>
          </RWCard>
        </div>
      </div>
    </b-col>
    <!-- end right section -->
  </b-row>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import RWWidgetGuides from "@/views/partials/widgets/rw/widgetGuides";
import RWWidgetBantuan from "@/views/partials/widgets/rw/widgetBantuan.vue";
import RWDomainManagement from "@/views/pages/product/slot/slotDomainManagement.vue";
import { breadcrumb, httpCode, util, ApiURL } from "@/common/mixins/general.js";

export default {
  name: "productDomainActive",
  mixins: [breadcrumb, httpCode, util, ApiURL],
  props: {
    /**
     * ------------------------------------
     * domain id get from url path
     * ------------------------------------
     */
    status: {
      type: Object,
      required: true
    }
  },
  components: {
    RWCard,
    RWWidgetBantuan,
    RWDomainManagement,
    RWWidgetGuides
  },
  data() {
    return {
      /**
       * -----------------------------------------
       * tampung init loading skeleton
       * -----------------------------------------
       */
      loading: {
        status: true,
        upsell: true,
        guide: true
      },
      slide: 0,
      sliding: null,
      xhr: {
        upsell: {},
        guide: []
      },
      success: {
        guide: false
      }
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },

    /**
     * ---------------------------------------
     * get upsell
     * ---------------------------------------
     */
    get_upsell() {
      this.xhr_domain("upsell", this.status.id)
        .then(({ data }) => {
          this.xhr.upsell = data.data.upsell;
        })
        .catch(({ response }) => {
          console.log("error upsel", response);
        })
        .finally(() => {
          this.loading.upsell = false;
        });
    },

    /**
     * -----------------------------------------
     * get guides
     * -----------------------------------------
     */
    get_guide() {
      this.http
        .get(this.end.general.guide)
        .then(({ data }) => {
          this.xhr.guide = data.data.guide;
          this.success.guide = true;
        })
        .catch(error => {
          this.xhr.guide = error;
        })
        .finally(() => {
          this.loading.guide = false;
        });
    }
  },
  mounted() {
    this.get_upsell();
    this.get_guide();

    setTimeout(() => {
      this.loading.status = false;
    }, 1000);

    this.mx_DomainBreadcrumb([
      {
        title: this.status.domain,
        route: "product/domain/" + this.status.id
      }
    ]);
  }
};
</script>

<style scoped>
.btn-active {
  background-color: #66eb00;
  color: white;
}

#left-help {
  background-color: #7cda24;
  color: white;
}
table tr td {
  text-align: center;
}

.carousel-item {
  background-image: linear-gradient(
    142deg,
    #47ccff 0%,
    #41ead1 100%
  ) !important;
  background-color: #47ccff !important;
}

.list-group-item {
  cursor: pointer;
}
</style>
