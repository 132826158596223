<template>
  <RWCard title="Informasi">
    <template #body>
      <p>
        Maaf, domain
        <span class="font-weight-bold">{{ $route.query.domain }}</span> tidak
        terdaftar di rumahweb, silahkan lakukan transfer domain terlebih dahulu
        untuk melakukan manage domain.
      </p>
    </template>
  </RWCard>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { breadcrumb } from "@/common/mixins/general.js";
export default {
  name: "slotDomainNotInRW",
  mixins: [breadcrumb],
  components: { RWCard },
  mounted() {
    this.mx_DomainBreadcrumb([{ title: this.$route.query.domain }]);
  }
};
</script>

<style scoped></style>
