<template>
  <RWCard title="Guide">
    <template #body>
      <v-skeleton-loader type="text@6" v-if="loading"></v-skeleton-loader>
      <ul v-else class="pl-3">
        <div v-if="success">
          <li v-for="(row, key) in data" :key="key">
            <a :href="row.link" target="_blank">
              {{ row.title }}
            </a>
          </li>
        </div>
        <div v-else>
          <b-alert show variant="danger">{{ data.message }}</b-alert>
        </div>
      </ul>
    </template>
  </RWCard>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet";
export default {
  name: "widgetGuides",
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    data: {
      required: true
    },
    success: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {};
  },
  components: {
    RWCard
  },
  mounted() {}
};
</script>
